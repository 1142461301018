/* eslint-disable react/no-array-index-key */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const Wrapper = styled.div`
  position: relative;
  background: #ffffff;
  font-size: 0.875rem;
  max-width: 542px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 22rem 0;
  height: 100%;
`;

const InnerWrapper = styled.div`
  h2 {
    margin: 2.5rem 0 0 0;
    color: #000000;
    background: #ffd700;
    display: inline-block;
    padding: 4px 8px;
  }
  h2#discount {
    font-weight: 600;
    font-size: 1rem;
    background: tomato;
    color: #ffffff;
  }
`;

const BasicSectionContainer = styled.div`
  background: #f7fafc;
  padding: 0 1rem 3rem;
  @media (min-width: 480px) {
    padding: 0 2rem 3rem;
  }
  @media (min-width: 680px) {
    padding: 0 3rem 3rem;
  }
`;

const DiscountSectionContainer = styled.div`
  background: #ffffff;
  padding: 0 1rem 3rem;
  @media (min-width: 480px) {
    padding: 0 2rem 3rem;
  }
  @media (min-width: 680px) {
    padding: 0 3rem 3rem;
  }
`;

const StyledTable = styled.table`
  width: 100%;

  td {
    vertical-align: bottom;
  }
  td:nth-child(1) {
    min-width: 90px;
    color: #000000;
  }
  td:nth-child(2),
  td:nth-child(3) {
    text-align: right;
    width: 160px;
  }
`;

const Row = styled.div`
  border-bottom: 1px #bee3f8 solid;
  padding: 0.5rem 0 0.2rem 0;
  height: 100%;
`;

const HeaderRow = styled.div`
  border-bottom: 1px #bee3f8 solid;
  padding: 0.5rem 0 0.2rem 0;
  height: 100%;
`;

const DiscountCell = styled.div`
  background: #ebf8ff;
  margin: -1rem 0 0 0;
  padding: 2rem 1rem 1rem;
  ul {
    padding: 0 1rem 0;
    list-style-type: square;
    list-style-position: outside;
    font-weight: 500;
    font-size: 1rem;
  }
`;

const ConditionWrapper = styled.div`
  background: #f6f6f4;
  padding: 2rem 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  @media (min-width: 480px) {
    padding: 2rem 2rem;
  }
  @media (min-width: 680px) {
    padding: 2rem 3rem;
  }
  ul {
    list-style-type: square;
    list-style-position: outside;
    margin: 0 1rem;
  }
  li {
    margin: 0 0 0.4rem 0;
  }
`;

const Heading = styled.div`
  font-weight: 500;
  margin: 0 0 0 0;
`;

const Total = styled.div`
  font-weight: 900;
`;

const Header = styled.div.attrs({ className: 'pt-8 pb-5 px-5' })`
  position: relative;
  text-align: right;
  background: ${props => props.headerColor};
  h2 {
    font-size: 1.25rem;
    font-weight: 800;
  }
  p {
    font-weight: 600;
  }
  position: sticky;
  top: 60px;
`;

const SpecialPricingLabel = styled.div`
  /* position: absolute; */
  padding: 4px 12px;
  background: tomato;
  color: #ffffff;
  font-size: 1.2rem;
  margin: -1rem 0 1rem 0;
  display: inline-block;
`;

const SpecialPricingDecription = styled.div`
  padding: 2rem 0;
  h3 {
    font-weight: 600;
    font-size: 1rem;
    margin: 0.5rem 0 0 0;
  }
`;

const ToTalWithTax = styled.div`
  margin: 0.25rem 0 0 0;
  font-size: 0.785rem;
  color: #2d3748;
`;

const Table = ({ data, headerColor }) => {
  //
  const { node: basic } = data[0];
  const {
    location: { name: locationName },
    pricingType: { paymentCycle },
    discount,
    conditions: { conditions },
  } = basic;

  const discountAvailable = data.map(prop => prop.node.discountedVariant.length).reduce((a, b) => a + b, 0);

  const [discountedGroups] = data.map(prop => [...prop.node.discountedVariant]);

  return (
    <Wrapper>
      <Header headerColor={headerColor}>
        {basic.packageNamePublic && <SpecialPricingLabel>{basic.packageNamePublic} Pricing</SpecialPricingLabel>}
        <h2>{paymentCycle}-month Package</h2>
        <p>{locationName}</p>
      </Header>

      <InnerWrapper>
        <BasicSectionContainer>
          {basic.description && (
            <>
              <SpecialPricingDecription>
                <ReactMarkdown source={basic.description} />
              </SpecialPricingDecription>
              <hr />
            </>
          )}
          <h2>First time payment</h2>
          <StyledTable>
            <tbody>
              <tr>
                <td>
                  <HeaderRow />
                </td>
                {data.map(prop => (
                  <td key={prop.node.id}>
                    <HeaderRow>
                      <Heading>
                        {prop.node.targetGroup.map(group => (
                          <div key={group.id}>{group.name}</div>
                        ))}
                      </Heading>
                    </HeaderRow>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <Row>Deposit</Row>
                </td>
                {data.map(prop => (
                  <td key={prop.node.id}>{prop.node.deposit ? <Row>RM {prop.node.deposit}</Row> : <Row>-</Row>}</td>
                ))}
              </tr>
              <tr>
                <td>
                  <Row>{`First ${paymentCycle} month`}</Row>
                </td>
                {data.map(prop => (
                  <td key={prop.node.id}>
                    <Row>RM {prop.node.fee * prop.node.pricingType.paymentCycle}</Row>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <Row>Registration</Row>
                </td>
                {data.map(prop => (
                  <td key={prop.node.id}>
                    <Row>RM {prop.node.registrationFee}</Row>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <HeaderRow>
                    <Total>Total</Total>
                  </HeaderRow>
                </td>
                {data.map(prop => (
                  <td key={prop.node.id}>
                    <HeaderRow>
                      <Total>
                        RM{' '}
                        {(
                          (prop.node.tax / 100) *
                            (prop.node.pricingType.paymentCycle * prop.node.fee +
                              prop.node.deposit +
                              prop.node.registrationFee) +
                          (prop.node.pricingType.paymentCycle * prop.node.fee +
                            prop.node.deposit +
                            prop.node.registrationFee)
                        ).toFixed(2)}
                      </Total>
                    </HeaderRow>
                  </td>
                ))}
              </tr>
            </tbody>
          </StyledTable>
          <ToTalWithTax>Total includes {basic.tax}% SST</ToTalWithTax>
          <h2>Subsequent payment every {paymentCycle} month</h2>
          <StyledTable>
            <tbody>
              <tr>
                <td>
                  <HeaderRow />
                </td>
                {data.map(prop => (
                  <td key={prop.node.id}>
                    <HeaderRow>
                      <Heading>
                        {prop.node.targetGroup.map(group => (
                          <div key={group.id}>{group.name}</div>
                        ))}
                      </Heading>
                    </HeaderRow>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <HeaderRow>
                    <Total>Total</Total>
                  </HeaderRow>
                </td>
                {data.map(prop => (
                  <td key={prop.node.id}>
                    <HeaderRow>
                      <Total>
                        RM{' '}
                        {(
                          (prop.node.tax / 100) * (prop.node.pricingType.paymentCycle * prop.node.fee) +
                          prop.node.pricingType.paymentCycle * prop.node.fee
                        ).toFixed(2)}
                      </Total>
                    </HeaderRow>
                  </td>
                ))}
              </tr>
            </tbody>
          </StyledTable>
          <ToTalWithTax>Total includes {basic.tax}% SST</ToTalWithTax>
        </BasicSectionContainer>
        {discountAvailable > 0 && (
          <DiscountSectionContainer>
            <h2 id="discount">{discount}% discount available</h2>
            <DiscountCell>
              <ul>
                {discountedGroups.map((group, index) => (
                  <li key={index}>{group}</li>
                ))}
              </ul>
            </DiscountCell>
            <h2>First time payment</h2>
            <StyledTable>
              <tbody>
                <tr>
                  <td>
                    <HeaderRow />
                  </td>
                  {data.map(prop => (
                    <td key={prop.node.id}>
                      <HeaderRow>
                        <Heading>
                          {prop.node.targetGroup.map(group => (
                            <div key={group.id}>{group.name}</div>
                          ))}
                        </Heading>
                      </HeaderRow>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <HeaderRow>
                      <Total className="pr-2">Total</Total>
                    </HeaderRow>
                  </td>
                  {data.map(prop => (
                    <td key={prop.node.id} style={{ background: '#fefcbf' }}>
                      <HeaderRow>
                        <Total>
                          RM{' '}
                          {(
                            prop.node.pricingType.paymentCycle *
                              (prop.node.fee - (prop.node.discount / 100) * prop.node.fee) +
                            prop.node.registrationFee +
                            (prop.node.tax / 100) *
                              (prop.node.pricingType.paymentCycle *
                                (prop.node.fee - (prop.node.discount / 100) * prop.node.fee) +
                                prop.node.registrationFee)
                          ).toFixed(2)}
                        </Total>
                      </HeaderRow>
                    </td>
                  ))}
                </tr>
              </tbody>
            </StyledTable>
            <ToTalWithTax>Total includes {basic.tax}% SST</ToTalWithTax>
            <h2>Subsequent payment every {paymentCycle} month</h2>
            <StyledTable>
              <tbody>
                <tr>
                  <td>
                    <HeaderRow />
                  </td>
                  {data.map(prop => (
                    <td key={prop.node.id}>
                      <HeaderRow>
                        <Heading>
                          {prop.node.targetGroup.map(group => (
                            <div key={group.id}>{group.name}</div>
                          ))}
                        </Heading>
                      </HeaderRow>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <HeaderRow>
                      <Total className="pr-2">Total</Total>
                    </HeaderRow>
                  </td>
                  {data.map(prop => (
                    <td key={prop.node.id} style={{ background: '#fefcbf' }}>
                      <HeaderRow>
                        <Total>
                          RM{' '}
                          {(
                            prop.node.pricingType.paymentCycle *
                              (prop.node.fee - (prop.node.discount / 100) * prop.node.fee) +
                            (prop.node.tax / 100) *
                              (prop.node.pricingType.paymentCycle *
                                (prop.node.fee - (prop.node.discount / 100) * prop.node.fee))
                          ).toFixed(2)}
                        </Total>
                      </HeaderRow>
                    </td>
                  ))}
                </tr>
              </tbody>
            </StyledTable>
            <ToTalWithTax>Total includes {basic.tax}% SST</ToTalWithTax>
          </DiscountSectionContainer>
        )}
      </InnerWrapper>
      <ConditionWrapper>
        <ul>
          {conditions.map(condition => (
            <li key={condition.id}>{condition.rule}</li>
          ))}
        </ul>
      </ConditionWrapper>
    </Wrapper>
  );
};

export default Table;

Table.propTypes = {
  data: PropTypes.array.isRequired,
  headerColor: PropTypes.string.isRequired,
};
