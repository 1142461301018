import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Table from './Table';

const QUERY = graphql`
  query {
    promo: allSanityPricing(
      filter: { location: { name: { eq: "SDAA Indoor Pool, CHIS, EIS" } }, pricingType: { paymentCycle: { eq: 3 } } }
    ) {
      edges {
        ...Pricing
      }
    }
  }
`;

const Section = styled.section`
  padding: 6rem 0;
  background: #c5dbf2;
  html {
  }
  scroll-behavior: smooth;
  h1 {
    text-align: center;
    margin: 0 0 2rem 0;
  }
`;

const Prices = () => {
  //
  const {
    promo: { edges: promotion },
  } = useStaticQuery(QUERY);

  return (
    <Section>
      <Table data={promotion} headerColor="#ffd700" />
    </Section>
  );
};

export default Prices;
